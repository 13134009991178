.radiobutton {
  &__item {
    display: flex;
    align-items: center;
  }
  input {
    width: auto;
    margin: 0;
    padding: 0;
    border: none;
    margin-bottom: 1px;
    margin-right: 10px;
  }
  label {
    margin-bottom: 0;
  }
}