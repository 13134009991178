.dg-test__module--radius {
  border-radius: 10px;
  &:last-child {
    border-radius: 10px;
  }
}

.btn-orange {
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-weight: 500;
  font-family: Montserrat;
}