.discount-text {
  text-align: center;
  font-size: 30px;
  line-height: 1.3;
  color: #FF7410;
  font-weight: bold;
  margin: 20px 0;
  > span {
    border-bottom: 2px solid #FF7410;
  }
  &__yellow {
    color: #ffb800;
  }
  &::after, &::before {
    content: '!';
    font-size: 64px;
    line-height: 1.1;
    transform: translateY(12px);
    display: inline-block;
    margin: 0 5px;
  }
}

@media (max-width: 980px) {
  .discount-text {
    &::after, &::before {
      display: none;
    }
  }
}

@media (max-width: 780px) {
  .discount-text {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .discount-text {
    font-size: 20px;
  }
}