$path-img: '../img/';

@function rems($pxs) {
  @return ($pxs / 16) + rem;
}

body {
  margin: 0;
  background-color: #f2f2f2;
}

.dg-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $color-main-blue;
  padding: 10px 0;
  &__logo {
    @media (max-width: 768px) {
      flex-basis: 50%;
      margin-bottom: 10px;
    }
    &--link {
      height: 40px;
      background: url("#{$path-img}icons/logo2.svg") no-repeat;
      padding-left: 64px;
      color: white;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      line-height: 18px;
      @media (max-width: 360px) {
        padding: 64px 0 0 0;
        background-position: 50% 0;
        margin-bottom: 50px;
      }
    }
    &--text {
      font-size: rems(18);
      font-weight: 300;
      margin: auto 0;
      white-space: nowrap;
      @media (max-width: 992px) {
        white-space: normal;
      }
    }
    &--tel {
      font-size: rems(12);
      font-weight: 400;
      @media (max-width: 360px) {
        padding: 10px 0;

      }
    }
  }
  &__search {
    position: relative;
    flex-grow: 3;
    padding: 0 30px;
    @media (max-width: 768px) {
      position: unset;
      display: none;
    }
    & form {
      width: 100%;
      & fieldset {
        border: none;
        padding: 0;
        position: relative;
        & .js-term {
          border: none;
          border-radius: 6px;
          font-size: rems(16);
          font-weight: 300;
          color: #555;
          padding: 12px 50px 12px 12px;
          height: 42px; // for MOZ
          width: 100%;
        }
        & button {
          width: 50px;
          height: 42px;
          position: absolute;
          top: 0;
          right: -2px;
          border: none;
          border-radius: 6px;
          background-color: #a1aeb8;
          text-align: center;
          cursor: pointer;
          & span {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -44%);
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url("#{$path-img}icons/search.svg") no-repeat;
            line-height: 36px;
          }
        }
      }
    }
    &--mobile {
      display: none;
    }
    & .dg-search-result {
      position: absolute;
      top: 100%;
      left: -25%;
      right: -25%;
      border-radius: 6px;
      margin-top: 10px;
      background-color: white;
      @media (max-width: 768px) {
        top: 80%;
        left: 10px;
        right: 10px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.3);
        z-index: -1;
      }
      & ul {
        margin: 0;
        padding: 0;
        list-style: none;
        &:first-child {
          margin-bottom: 10px;
        }
        & li {
          & a {
            text-decoration: none;
            color: #445d74;
            display: block;
            font-size: rems(14);
            width: 100%;
            &:hover {
              background-color: #eee;
            }
          }

        }
      }
      &__item {
        & a {
          padding: 4px 10px;
        }
      }
      &__header {
        padding: 10px;
        color: #999;
        font-size: rems(18);
        font-weight: 600;
        border-bottom: 1px dashed #eee;
      }
      &__footer {
        border-top: 1px dashed #eee;
        a {
          padding: 10px;
          text-align: right;
          &:last-child:hover {
            border-radius: 0 0 6px 6px;
          }
        }
      }
      &__none {
        display: block;
        padding: 20px;
      }
    }
  } // search

  &__phone {
    color: #eee;
    font-weight: 300;
    padding-left: 42px;
    background: url("#{$path-img}icons/telephone.svg") no-repeat;
    line-height: 1.2;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    & span {
      white-space: nowrap;
    }
    & .free {
      font-size: rems(14);
    }
  }
  &__login {
    & .dg-login {
      padding: 8px 16px;
      color: white;
      font-size: rems(14);
      border-radius: 6px;
      display: inline-block;
      cursor: pointer;
      &__icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 16px;
        background: url("#{$path-img}icons/enter.svg") no-repeat;
        vertical-align: bottom;
      }
    }
  }

  .dg-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1260px;
    padding: 10px;
    margin: 0 auto;
  }
}

.dg-mobile-m {
  display: flex;
  justify-content: space-between;
  flex-basis: 68%;
  @media (max-width: 1024px) and (min-width: 501px) {
    flex-basis: 50%;
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    flex-basis: 100%;
  }
}


.dg-header__login {
  display: flex;
  align-items: center;
  .kr-mobile-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 6px;
    padding: 6px 16px 6px 10px;
    white-space: nowrap;
    min-width: 220px;
    z-index: 100;
    & img {
      width: 30px;
      margin-right: 6px;
      border-radius: 50%;
    }
    & .dg-name {
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      max-width: 200px;
      vertical-align: sub;
      cursor: context-menu;
      text-overflow: ellipsis;
      &:after {
        content: '‹';
        color: white;
        position: absolute;
        right: 3px;
        transform: rotate(-90deg);
        font-size: rems(18);
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .dg-nav {
      display: none;
      position: absolute;
    }
    @media (max-width: 1024px) {
      min-width: unset;
    }
    &:hover {
      @media (min-width: 1025px) {
        .dg-nav {
          display: block;
          position: absolute;
          left: 0;
          top: 100%;
          margin: 0;
          padding: 18px 0 0 0;
          width: 100%;
          list-style: none;
          &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-top: 0 solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 12px solid white;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 18px;
            right: 3px;
            bottom: 3px;
            left: 3px;
            box-shadow: 0 6px 16px 0 rgba(0,0,0,.3);
            z-index: -1;
          }
          & li {
            margin: 0;
            background-color: white;
            overflow: hidden;
            &:first-child {
              border-radius: 6px 6px 0 0;
            }
            &:last-child {
              border-radius: 0 0 6px 6px;
            }
            & a {
              padding: 10px 20px 10px;
              display: block;
              text-decoration: none;
              color: #546673;
              &:hover {
                background-color: #e4e7ea;
              }
            }
          }
        }
      }
    }
  }
}



.dg-system-message {
  position: relative;
  display: flex;
  &__icon {
    width: 20px;
    margin: auto 10px;
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    cursor: pointer;
    &--value {
      position: absolute;
      min-width: 16px;
      text-align: center;
      line-height: 1;
      top: -50%;
      right: -50%;
      font-size: rems(14);
      color: white;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: rems(18);
        width: rems(18);
        background-color: #ff0000;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
  &__wrapper {
    display: none;
    position: absolute;
    padding-top: 22px;
    margin-top: 100%;
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
    &:before {
       content: '';
       position: absolute;
       top: 6px;
       left: 50%;
       transform: translateX(-50%);
       border-left: 14px solid transparent;
       border-top: 0 solid transparent;
       border-right: 14px solid transparent;
       border-bottom: 16px solid white;
     }
    @media (max-width: 1024px) and (min-width: 601px) {
      right: 0;
      left: unset;
      transform: translateX(2%);
      &:before {
        left: unset;
        right: 0;
      }
    }
    @media (max-width: 600px) {
      top: 100%;
      left: 50%;
      max-width: unset;
      width: 98vw;
      margin-top: 0;
      &:before {
        left: 50%;
      }
    }
    &.show {
      display: block;
    }
  }
  &__inner {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    min-width: 400px;
    @media (max-width: 600px) {
      min-width: unset;
    }

    &--list {
      list-style-type: none;
      margin: 0;
      padding: 0 4px;
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ff9200;
      }
      &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 24px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        box-shadow: 0 6px 16px 0 rgba(0,0,0,.3);
        z-index: -1;
      }
    }
  }
  &__item {
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid #eee;
    position: relative;
    font-size: rems(14);
    line-height: 1.3;
    &:last-child {
      border-bottom: none;
    }
    &--del {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
      font-size: rems(30);
      color: #666;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
  &__unscroll {
    padding: 10px 0 2px;
    text-align: center;
    border-top: 1px solid #eee;
    &--btn {
      display: inline-block;
      padding: 6px 20px;
      border-radius: 4px;
      color: white;
      text-decoration: none;
      font-weight: 600;
      background-color: #ff8f07;
    }
  }
}


// mobile menu

#toggle-search,
#toggle-menu {
  display: none;
}

.close {
  cursor: pointer;
}

.toggle-search-container {
  display: none;
  @media (max-width: 768px){
    display: flex;
  }
  & .close {
    margin: auto 10px;
    width: 26px;
    height: 26px;
    background: url("#{$path-img}icons/search.svg") no-repeat;
  }
}

.toggle-menu-container {
  @media (min-width: 1025px) {
    display: none;
  }
  & .close {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 26px;
    border-bottom: 2px solid white;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 26px;
      left: 0;
      height: 2px;
      background-color: white;
    }
    &:before {
      top: 20%;
    }
    &:after {
      top: 62%;
    }
  }
}

#toggle-search:checked {
  & ~ .dg-header__search {
    position: absolute;
    display: block;
    flex-direction: column;
    width: 100%;
    left: 0;
    top: 100%;
    padding: 10px;
    background-color: rgba(0,0,0,.3);
  }
  & ~ .toggle-search-container {
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: 20px solid transparent;
      border-top: 0 solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 24px solid white;
      z-index: 999;
    }
    & .close {
      border-bottom: none;
      transition: ease-out 0.5s;
      position: relative;
      background: unset;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 26px;
        height: 2px;
        background-color: white;
        top: 50%;
        left: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

#toggle-menu:checked {
  & ~ .dg-nav {
    display: block;
    top: 50px;
    right: 0;
    background-color: white;
    margin: 0;
    padding: 20px;
    list-style-type: none;
    border-radius: 10px;
    box-shadow: 0 6px 16px -4px rgba(0,0,0,.3);
    & li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      & a {
        text-decoration: none;
        color: #2a2a32;
      }
    }
  }
  & ~ .toggle-menu-container {
    & .close {
      border-bottom: none;
      transition: ease-out 0.5s;
      &:before,
      &:after {
        top: 50%;
        left: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 38px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-top: 0 solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid white;
    }
  }
}


.dg-header {
  @media (max-width: 1024px) and (min-width: 601px) {
    padding: 0;
  }
  @media (max-width: 600px) {
    padding: 0;
    position: relative;
    & .dg-flex {
      flex-direction: column;
    }
  }
}
