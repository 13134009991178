@function rems($pxs) {
  @return ($pxs / 16) + rem;
}

.order_1{
  @media (max-width: 768px) {
    order: 1;
  }
}
.order_2{
  @media (max-width: 768px) {
    order: 2;
  }
}
.main__wrapper{
  max-width: 1218px;
  margin: 0 auto;
  position: relative;
}
.dg-table-wrapper{
  overflow-x: auto;
}
.dg-padding-30{
  padding: 30px 0;
}
.dg-table-about{
  margin: 0;
  padding: 20px;
  background-color: #fff;
  font-weight: 400;
  min-width: 580px;
  &__row{
    list-style-type: none;
    display: flex;
    padding: 6px 10px;
    &:nth-child(odd) {
      background-color: #f2f2f2;
    }
    &--title{
      flex-basis: 35%;
      font-size: rems(18);
      font-weight: 500;
    }
    &--text{
      flex-basis: 65%;
      padding: 3px 10px 0 10px;
      font-size: rems(16);
    }
  }
}
.st_details{
  &_bg{
    padding: 30px 0;
    .dg-course__about--pager{
      width: 100%;
    }
  }
  &_title{
    margin-bottom: 30px;
    font-size: rems(30);
    display: block;
    font-weight: 600;
    &--drop-down {
      margin-bottom: 0px;
      &:after {
        content: '';
        margin-left: 4px;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url('#{$path-img}tmp/podrobnee.svg') no-repeat;
        background-size: contain;
        position: relative;
        top: 1px;
      }
      &.active {
        background-color: #eceff2;
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.st_menu{
  background-color: #fff;
  padding: 8px 16px 16px;
  font-size: rems(20);
  font-weight: 300;
  margin: 0 0 42px;
  a{
    white-space: nowrap;
    display: block;
    line-height: 32px;
    position: relative;
    color: #123;
    text-decoration: none;
    z-index: 1;
  }

  .active{
    color: #fff;
    &:before{
      content: '';
      position: absolute;
      left: -8px;
      right: -8px;
      bottom: 0;
      top: 0;
      background-color: #2a2a32;
      border-radius: 6px;
      z-index: -1;
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.16);
      border: none;
    }
  }
}
.st_doc_bg{
  background: #fff;
  &.hide {
    display: none;
  }
  &.active {
    display: block;
    background-color: #f7f7f7;
    border-right: 1px solid #b0bbc4;
    border-bottom: 1px solid #b0bbc4;
    border-left: 1px solid #b0bbc4;
  }
  &--drop-down {
    margin-top: 30px;
  }
}

.st_education{
  &_bg{
    background: #fff;
    margin: 40px 0 0;
    padding: 20px;
  }
}
.st_another{
  div{
    cursor: pointer;
  }
}
.st_reg{
  padding: 20px;
  max-height: 100vh;
  overflow-y: scroll;
  .st_details_title{
    text-align: center;
  }
  &_body{
    a{
      display: block;
      margin: 10px 0;
    }
  }
}