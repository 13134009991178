.flex-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item {
    flex-basis: 49%;
    justify-content: flex-start;
    line-height: 20px;
  }
}