.price-boxes {
  display: flex;
  margin: 35px 0 40px;

  &__item {
    padding: 20px 24px 24px 24px;
    border: 1px solid #fff;
    border-radius: 6px;
    min-width: 340px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  &__top {
    display: flex;
    margin-bottom: 8px;
  }
  &__hour {
    color: #fff;
    margin-right: 35px;
    p {
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
    }
    span {
      display: block;
      font-size: 16px;
      line-height: 110%;
      color: #FFFAE9;
      font-weight: normal;
    }
  }
  &__price {
    color: #fff;
    p {
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
    }
    span {
      display: block;
      font-size: 16px;
      line-height: 110%;
      color: #FFFAE9;
      font-weight: normal;
    }
  }
  &__button {
    width: 100%;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    display: block;
    padding: 21px 20px;
  }
}

.price-boxes--black {
  justify-content: center;
  .price-boxes__item {
    border-color: #000;
  }

  .price-boxes__price,
  .price-boxes__hour {
    color: #333333;
    span {
      color: #333333;
    }
  }
}

@media (max-width: 1024px) {
  .price-boxes--black {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .price-boxes {
    flex-wrap: wrap;
    &__item {
      min-width: auto;
      width: 100%;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}