@function rems($pxs) {
  @return ($pxs / 16) + rem;
}

.dg-blind-menu {
  position: fixed;
  z-index: 9999;
  display: none;
  background-color: #ECECEC;
  width: 100%;
  &.active {
    display: block;
  }
  &__list {
    display: flex;
    padding: 20px 10px;
    list-style-type: none;
    &--item {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    &--link {
      display: inline-block;
      background-color: black;
      padding: 10px;
      color: white;
      font-size: 20px;
      &:hover {
        color: white;
        background-color: red;
      }
      &.active {
        background-color: #FF0000FF;
      }
    }
    &--text {
      display: inline-block;
      padding: 10px;
      color: black;
      margin-right: 10px;
      font-size: 20px;
    }
    &--checbox {
      display: flex;
      margin-right: auto;
      text-align: center;
      font-size: 20px;
      input {
        display: none;
      }
      label {
        display: block;
        position: relative;
        width: 300px;
        height: 46px;
        line-height: 44px;
        background: black;
        cursor: pointer;
        &:after {
          content: "Черно-белые";
          width: 50%;
          position: absolute;
          left: 0;
          z-index: 99;
          transition: all 600ms;
          color: white;
        }
        &:before {
          content: "Цветные";
          position: absolute;
          width: 50%;
          right: 0;
          color: black;
          transition: all 600ms;
          z-index: 99;
        }
        span {
          width: 50%;
          display: inline-block;
          height: 100%;
          position: absolute;
          right: 0;
          background: white;
          transition: all 600ms;
          border: 1px solid black;
        }
      }
      #dg-blind-mode__img:checked + label {
        &:after {
          color: black;
        }
        &:before {
          color: white;
        }
        span {
          right: 150px;
        }
      }
    }
  }
  &__activate {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-left: 10px;
    width: 56px;
    height: 56px;
    //background-color: white;
    //border-left: 1px solid #eaeaea;
    position: fixed;
    right: 0;
    top: 32%;
    transform: translateY(-50%);
    background-color: #999;
    z-index: 9999;
    @media (max-width: 990px) {
      display: none;
    }
    & svg {
      width: 34px;
      height: 34px;
      fill: white;
    }
    & span {
      font-size: 20px;
      text-transform: uppercase;
      position: absolute;
      border-right: 1px solid #eee;
      white-space: nowrap;
      top: 240%;
      transform: rotate(-90deg) translateX(-80px);
      padding: 18px;
      background-color: #999;
      color: white;
      line-height: 1;
    }
    &:hover {
      background-color: #f60;
      & span {
        background-color: #f60;
        color: white;
      }
      & svg {
        fill: white;
      }
    }
    &.active {
      background-color: #ff6c00;
      & svg {
        fill: white;
      }
      &:hover {
        background-color: #FF6C00FF;
        &::after {
          content: 'Выйти в обычную версию';
          background-color: #FF6C00FF;
        }
      }
    }
  }
}

html.blind-mode {
  font-weight: 500;
  color: black;
  &-grey {
    & img {
      filter: grayscale(100%)
    }
  }
  & body {
    background-color: white;
  }
  & .dg-height {
    padding-top: 0;
  }
  & .dg-header {
    padding: 76px 0 10px;
    position: relative;
    & .dg-flex {
      flex-direction: column;
    }
    &__logo {
      flex-basis: 50%;
      margin-bottom: 20px;
      &--text {
        margin: auto 0 6px;
      }
      &--link {
        padding-left: 76px;
        height: unset;
        min-height: 40px;
      }
    }
    & .dg-mobile-m {
      flex-basis: 50%;
      justify-content: flex-end;
      & .toggle-search-container {
        display: flex;
      }
    }
    &__search {
      position: unset;
      display: none;
      & form {
        & fieldset {
          & button {
            background-color: black;
          }
        }
      }
      & .dg-search-result {
        top: unset;
        left: 0;
        right: 0;
        border-radius: 0 0 6px 6px;
        background-color: black;
        & ul li a {
          color: white;
          &:hover {
            color: #ff8f07;
            background-color: #333;
          }
        }
        &__header {
          color: white;
          border-bottom-color: white;
        }
        &__item {
          & a {
            padding: 10px;
            color: white;
          }
        }
      }
    }
    & #toggle-search:checked {
      &~.dg-header__search {
        background-color: #ff8f07;
      }
      &~.toggle-search-container:before {
        border-bottom: 24px solid #ff8f07;
      }
    }
    & .dg-name {
      max-width: 50vw;
    }
    & .kr-mobile-dropdown:hover .dg-nav {
      &::before {
        border-bottom-color: #ff8f07;
      }
      & li {
        border-left: 4px solid #ff8f07;
        border-right: 4px solid #ff8f07;
        &:first-child {
          border-top: 4px solid #ff8f07;
        }
        &:last-child {
          border-bottom: 4px solid #ff8f07;
        }
      }
    }
  }
  & .dg-system-message {
    &__wrapper {
      position: fixed;
      margin-top: unset;
      top: 10vh;
      width: 80vw;
      max-width: unset;
      max-height: 58vh;
      z-index: 999;
      &::before {
        content: unset;
      }
    }
    &__inner {
      min-width: 80vw;
      border: 4px solid #ff8f07;
      filter: drop-shadow(0 20px 20px rgba(0,0,0,.5));
      &--list {
        &::after {
          content: unset;
        }
      }
    }
  }
  & .dg-top-bg {
    background: #666;
  }
  & .container {
    flex-direction: column;
    padding: 0 10px;
    max-width: 100%;
    & .dg-aside-left {
      margin-right: 0;
      min-width: unset;
      max-width: unset;
      &__filter {
        padding: unset;
        border-radius: unset;
        background-color: unset;
      }
    }
    & .dg-main {
      flex-grow: unset;
    }
  }
  & .dg-blind-menu {
    display: block;
    &__activate {
      display: none;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style-type: none;
      &--item {
        padding: 10px 10px 10px 0;
      }
      &--link {
        display: inline-block;
        padding: 10px;
        background-color: black;
        color: white;
        text-decoration: none;
        &:hover {
          background-color: red;
        }
      }
    }
  }
  & .dg-slider__title--link a {
    border: none;
    background-color: black;
    color: white;
  }
  & .dg-course {
    width: 24%;
    box-shadow: unset;
    border: 2px solid black;
    &:hover {
      box-shadow: unset;
    }
    &__description {
      font-size: rems(20);
    }
    &__header {
      color: black;
    }
    &__footer {
      &--time,
      &--price {
        color: black;
      }
    }
    &__title a {
      font-size: rems(18);
      font-weight: 700;
    }
    &__header-bg {
      background-image: unset !important;
      background-color: black;
      &--row {
        flex-direction: column;
        & .course-start,
        & .course-period,
        & .course-form {
          width: 100%;
          margin: 0 0 20px;
        }
        & .title,
        & .dg-small-font {
          color: white;
        }
      }
      &--sale {
        margin: 0 0 20px;
        background-color: white;
      }
    }
    &__privilege-bg {
      background: black;
    }
    &__program {
      & .dg-section.title-dark {
        background-color: black;

      }
    }
  }
  & .dg-our-advantages__show-list--item .dg-flex-table__header {
    color: black;
    font-weight: 700;
    text-align: center;
  }
  & .dg-issued-bg {
    background-color: white;
  }
  & .dg-issued-docs__list--item {
    background: unset;
  }
  & .dg-advantages {
    max-width: 100%;
    &--item {
      & .text {
        font-weight: 600;
      }
    }
    & .no::before {
      background-color: black;
    }
    &__tips--remote {
      margin-right: 1.5%;
    }
  }
  & .dg-footer {
    background: black;
  }
  & .dg-filter {
    padding: 10px 10px 2px;
    background-color: black;
    color: white;
    & .dg-hide {
      display: none;
    }
    &__hider {
      display: block;
      margin: 0 0 10px;
      padding: 10px;
      background-color: black;
      &:hover {
        background-color: #ffad25;
      }
    }
    &__list {
      font-size: rems(20);
      & li {
        align-items: center;
        & input {
          width: rems(20);
          height: rems(20);
        }
      }
    }
    &__section {
      &:last-child {
        margin-bottom: 10px;
      }
      &--btn-wrapper {
        padding: 20px;
        & a {
          color: white;
          border-color: white;
          font-size: rems(20);
          &:hover {
            background-color: #333;
          }
        }
      }
      &--cost {
        & input {
          font-size: rems(20);
        }
      }
    }
  }
  & h1 {
    font-size: rems(30);
  }
  & h2, h3, h4 {
    font-size: rems(22);
  }
  & .dg-order {
    border: 4px solid #ff8f07;
  }
  & .dg-test {
    &__module {
      margin-bottom: 20px;
      padding: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        padding: 20px;
      }
      &--section {
        flex-direction: column;
        & .dg-ls {
          border-radius: 6px 6px 0 0;
          border-right: 1px solid #ccd2d7;
          border-bottom: unset;
        }
        & .dg-rs {
          border-radius: 0 0 6px 6px;
        }
        & span,
        & p {
          font-size: rems(16) !important;
        }
      }
    }
  }
  & .order_2{
    order: 2;
  }
  & .order_1 {
    max-width: unset;
    .theiaStickySidebar{
      position: static !important;
    }
    .st_menu {
      a {
        line-height: 1;
      }
    }
  }
}