$color-main-blue: #2a2a32;
$color-link: #315CAB;//
$color-link-hover: #0A7FBB;//


$path-img: '../img/';


$path-font: '../font/' !global;


$width-xs: 482px;
$width-sm: 801px;
$width-md: 1026px;

$max-w-xs: "(max-width: 480px)";
$max-w-sm: "(max-width: 768px)";
$max-w-md: "(max-width: 992px)"; //#{$max-w-md}

$min-w-sm: "screen and (min-width: 801px)";
$min-w-md: "screen and (min-width: 992px)";
$min-w-lg: "screen and (min-width: 1200px)";
